import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import {
  AUserData,
  GetUserDataReqObj,
  ManageApproverReqObj,
  RegistObj,
  UpdateUserObj,
} from "../../types/user";
import { BASIC_URL } from "../basicurl";

export const userstoreAPI = createApi({
  reducerPath: "userstoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/user`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["UserList", "User"],
  endpoints: (builder) => {
    return {
      getUserList: builder.query<AUserData[], GetUserDataReqObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: (_, __, obj) => {
          return [
            "UserList",
            {
              id: obj.id,
              type: "UserList",
            },
          ];
        },
        transformResponse: (res: BackendDataShape<AUserData[]>) => {
          return res.data;
        },
      }),
      createUser: builder.mutation<
        BackendDataShape<Array<{ id: number }>>,
        RegistObj
      >({
        query: (obj) => {
          return {
            url: "/register",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
        // transformResponse: (res: BackendDataShape<{ id: number }>) => res.data,
      }),
      updateUser: builder.mutation<
        BackendDataShape<Array<{ id: number }>>,
        Omit<UpdateUserObj, "password">
      >({
        query: ({ userId, ...rest }) => {
          return {
            url: `/update/${userId}`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["UserList"],
        // transformResponse: (res: BackendDataShape<{ id: number }>) => res.data,
      }),
      manageApprover: builder.mutation<unknown, ManageApproverReqObj>({
        query: (obj) => {
          return {
            url: "/manageapprover",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["UserList"],
      }),
    };
  },
});

export const {
  useGetUserListQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useManageApproverMutation,
  util: { resetApiState: resetUserStoreAPI },
} = userstoreAPI;
