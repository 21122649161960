import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { COLORS } from "../../assets/theme";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import {
  AChannel,
  AChannelVeryShort,
  AChannelWithColor,
  ChannelCreateObj,
  ChannelReqObj,
  ChannelUpdateObj,
} from "../../types/channel";
import { AWarehouseVeryShort } from "../../types/channel/warehouse";
import { BASIC_URL } from "../basicurl";

export const channelstoreAPI = createApi({
  reducerPath: "channelstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/channel`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ChannelList", "ChannelLovList", "ChannelWarehouseLovList"],
  endpoints: (builder) => {
    return {
      getChannels: builder.query<AChannelWithColor[], ChannelReqObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: ["ChannelList"],
        transformResponse: (res: BackendDataShape<AChannel[]>) => {
          const colors = [
            COLORS.orange_1,
            COLORS.purple,
            COLORS.green,
            COLORS.brown,
          ];
          return res.data.map((val, idx) => {
            return {
              ...val,
              color: colors[idx % colors.length],
            };
          });
        },
      }),
      getChannelLov: builder.query<AChannelVeryShort[], ChannelReqObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/lov" + params,
            method: "GET",
          };
        },
        providesTags: ["ChannelLovList"],
        transformResponse: (res: BackendDataShape<AChannelVeryShort[]>) => {
          return res.data;
        },
      }),
      getChannelWarehouseLov: builder.query<
        AWarehouseVeryShort[],
        { channelId: number }
      >({
        query: ({ channelId }) => {
          return {
            url: `/${channelId}/warehouse/lov`,
            method: "GET",
          };
        },
        providesTags: ["ChannelWarehouseLovList"],
        transformResponse: (res: BackendDataShape<AWarehouseVeryShort[]>) => {
          return res.data;
        },
      }),
      createChannel: builder.mutation<unknown, ChannelCreateObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ChannelList"],
      }),
      updateChannel: builder.mutation<unknown, ChannelUpdateObj>({
        query: ({ id, ...rest }) => {
          return {
            url: `/update/${id}`,
            body: rest,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ChannelList"],
      }),
    };
  },
});

export const {
  useGetChannelsQuery,
  useGetChannelLovQuery,
  useGetChannelWarehouseLovQuery,
  useCreateChannelMutation,
  useUpdateChannelMutation,
  util: { resetApiState: resetChannelstoreAPI },
} = channelstoreAPI;
