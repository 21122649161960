import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import { ADockWithTruck, DockStatus } from "../../types/channel/dock";
import { AReservationWithWalkin } from "../../types/reservation";
import { ATruck, ATruckOpt } from "../../types/truck";
import { BASIC_URL } from "../basicurl";

export const truckstoreAPI = createApi({
  reducerPath: "truckstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/truck`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["TruckList", "TruckMonitoringList", "TruckDockingMonitoringList"],
  endpoints: (builder) => {
    return {
      getTrucks: builder.query<ATruck[], {}>({
        query: () => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["TruckList"],
        transformResponse: (res: BackendDataShape<ATruck[]>) => {
          return res.data;
        },
      }),
      getTrucksOptions: builder.query<ATruckOpt[], {}>({
        query: () => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["TruckList"],
        transformResponse: (res: BackendDataShape<ATruckOpt[]>) => {
          return res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.nopol,
            };
          });
        },
      }),
      getTruckMonitoring: builder.query<AReservationWithWalkin[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: `/monitoring/noauth` + params,
            method: "GET",
          };
        },
        providesTags: () => ["TruckMonitoringList"],
        transformResponse: (
          res: BackendDataShape<AReservationWithWalkin[]>
        ) => {
          return res.data;
        },
      }),
      getTruckDockingMonitoring: builder.query<
        ADockWithTruck[],
        { warehouseId: number; status: DockStatus[] }
      >({
        query: ({ warehouseId, ...rest }) => {
          let params = "";
          if (rest) {
            const { status } = rest;
            const statusDock = status.join(",");
            params = convertToEncodedURL({ ...rest, status: statusDock });
            params = params.length ? "?" + params : "";
          }
          return {
            url: `/find_truck/warehouse/${warehouseId}/noauth` + params,
            method: "GET",
          };
        },
        providesTags: () => ["TruckDockingMonitoringList"],
        transformResponse: (res: BackendDataShape<ADockWithTruck[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetTrucksQuery,
  useGetTrucksOptionsQuery,
  useGetTruckMonitoringQuery,
  useGetTruckDockingMonitoringQuery,
  util: { resetApiState: resetTruckstoreAPI },
} = truckstoreAPI;
