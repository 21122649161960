import React from "react";
import {
  useGetChannelLovQuery,
  useGetChannelsQuery,
} from "../../redux/ChannelStore/channelstoreAPI";
import { ChannelReqObj, ChannelShortOpt } from "../../types/channel";

type RetVal = {
  arr: ChannelShortOpt[];
  isLoading: boolean;
};

const useChannelShortOptions = (
  arg?: ChannelReqObj,
  opt?: { skip?: boolean }
): RetVal => {
  const { data, isLoading, isFetching } = useGetChannelLovQuery(arg || {}, {
    skip: opt?.skip,
  });
  // data?.sort()
  const arr = data?.slice();
  arr?.sort((a, b) => {
    return a.description.localeCompare(b.description);
  });

  return {
    arr:
      arr?.map((val) => {
        return {
          label: val.description,
          value: val.id,
          ...val,
        };
      }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useChannelShortOptions;
