import { lazy, Suspense } from "react";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import WarehouseDockMonitoringPage from "./pages/WarehouseDockMonitoring/WarehouseDockMonitoringPage";

const QueueManagementMonitoringPage = lazy(
  () =>
    import("./pages/QueueManagementAndMonitoring/QueueManagementMonitoringPage")
);

const App = () => {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/truck"
            element={
              <Suspense fallback={<>Loading...</>}>
                <QueueManagementMonitoringPage />
              </Suspense>
            }
          />
          <Route
            path="/dock"
            element={
              <Suspense fallback={<>Loading...</>}>
                <WarehouseDockMonitoringPage />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/truck" />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
