import { useGetChannelWarehouseLovQuery } from "../../redux/ChannelStore/channelstoreAPI";
// import { ChannelReqObj, ChannelShortOpt } from "../../types/channel";
import { WarehouseShortOpt } from "../../types/channel/warehouse";

type RetVal = {
  arr: WarehouseShortOpt[];
  isLoading: boolean;
};

const useChannelWarehouseLovOptions = (
  arg: { channelId: number },
  opt?: { skip?: boolean }
): RetVal => {
  const { data, isLoading, isFetching } = useGetChannelWarehouseLovQuery(arg, {
    skip: opt?.skip,
  });

  const arr = data?.slice();
  arr?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return {
    arr:
      arr?.map((val) => {
        return {
          label: val.name,
          value: val.id,
          ...val,
        };
      }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useChannelWarehouseLovOptions;
