import React, { useState } from "react";
import {
  CompanyText,
  CompanyTextWrapper,
  HeaderCardText,
  LicensePlateText,
  NonCompanyText,
  StyledCard,
  StyledCardContainerScroller,
  StyledCardContainerWarehouse,
  StyledContainer,
  StyledHeaderTitle,
} from "../QueueManagementAndMonitoring/style";
import io, { Socket } from "socket.io-client";
import Select, { SingleValue } from "react-select";
import { useSearchParams } from "react-router-dom";
import { ServerToClientEvents } from "../../types/socket";
import useCustomSelectStyle from "../../hooks/useCustomSelectStyle";
import TimeDisplayComponent from "../QueueManagementAndMonitoring/TimeDisplayComponent";
import { COLORS } from "../../assets/theme";
import { useGetTruckDockingMonitoringQuery } from "../../redux/TruckStore/truckstoreAPI";
import { ADockWithTruck } from "../../types/channel/dock";
import { ChannelOpt } from "../../types/channel";
import { AReservationWithWalkin } from "../../types/reservation";
import { checkIfDev, prettifyNopol } from "../../tools";
import { format } from "date-fns";
import { WarehouseOpt } from "../../types/channel/warehouse";
import useChannelShortOptions from "../../hooks/channels/useChannelShortOptions";
import useChannelWarehouseLovOptions from "../../hooks/channels/useChannelWarehouseLovOptions";
import { CircularProgress } from "@material-ui/core";
type Props = {};
const socket: Socket<ServerToClientEvents> = io(
  `${process.env.REACT_APP_SOCKET_IO_BASIC_URL}`
);

const WarehouseDockMonitoringPage = (props: Props) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const customSelectStyle = useCustomSelectStyle();
  const [selectedChannelOpt, setSelectedChannelOpt] =
    useState<SingleValue<{ label: string; value: number }>>();
  const [selectedWarehouseOpt, setSelectedWarehouseOpt] =
    useState<SingleValue<{ label: string; value: number }>>();
  const [searchParams, setSearchParams] = useSearchParams();
  const warehouseParamsId = searchParams.get("warehouse");
  const channelParamsId = searchParams.get("channelId");
  const { arr: channelLovOpts, isLoading: isLoadingChannelLovOpts } =
    useChannelShortOptions();
  const {
    arr: channelWarehouseLovOpts,
    isLoading: isLoadingChannelWarehouseOpts,
  } = useChannelWarehouseLovOptions(
    { channelId: selectedChannelOpt?.value ?? 0 },
    { skip: !selectedChannelOpt }
  );

  const { data, refetch, isLoading, isFetching } =
    useGetTruckDockingMonitoringQuery(
      {
        warehouseId: warehouseParamsId ? Number(warehouseParamsId) : 0,
        status: ["docking", "queue"],
      },
      { skip: !warehouseParamsId }
    );

  const loading = isLoading || isFetching;
  const truckDockReservationMemo = React.useMemo<
    ADockWithTruck[] | undefined
  >(() => {
    /**
     * @todo untuk sementara jika tidak ada `warehouseParamsId`, tidak ada data yang dimunculkan.
     * tolong dibuatkan tampilan ketika tidak ada data karena:
     * - belum memilih warehouse
     * - tidak ada warehouse didalam channel yang dipilih
     */
    if (!data || !warehouseParamsId) return [];
    let trucks = [...data];
    return trucks;
  }, [data, warehouseParamsId]);

  // console.log({ truckDockReservationMemo });
  React.useEffect(() => {
    console.log({ channelLovOpts, warehouseParamsId, data });
  }, [warehouseParamsId, channelLovOpts, data]);

  React.useEffect(() => {
    // channelParamsId ? selectedChannelOpt()
    setSelectedChannelOpt((prev) => {
      if (channelParamsId) {
        if (prev?.value === parseInt(channelParamsId)) return prev;
        const found = channelLovOpts.find(({ id }) => {
          return id === parseInt(channelParamsId);
        });
        return found ?? null;
      } else {
        return null;
      }
    });
  }, [channelParamsId, channelLovOpts]);

  React.useEffect(() => {
    // channelParamsId ? selectedChannelOpt()
    setSelectedWarehouseOpt((prev) => {
      if (warehouseParamsId) {
        if (prev?.value === parseInt(warehouseParamsId)) return prev;
        const found = channelWarehouseLovOpts.find(({ id }) => {
          return id === parseInt(warehouseParamsId);
        });
        return found ?? null;
      } else {
        return null;
      }
    });
  }, [warehouseParamsId, channelWarehouseLovOpts]);

  React.useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("updateTruckStatusDocking", (reservation) => {
      if (reservation) {
        refetch();
        checkIfDev() &&
          console.log("UPDATE TRUCK DOCK RESERVATION", { reservation });
      }
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("updateTruckStatusDocking");
    };
  }, [refetch]);

  const getTruckAngkutanReservation = (ticket: AReservationWithWalkin) => {
    if (ticket && ticket.truck) {
      return ticket.truck.angkutan;
    } else {
      return "-";
    }
  };
  const getTruckTypeReservation = (ticket: AReservationWithWalkin) => {
    if (ticket && ticket.truck) {
      return ticket.truck.type;
    } else {
      return "-";
    }
  };
  const getMaterialReservation = (ticket: AReservationWithWalkin) => {
    if (ticket && ticket.material) {
      return ticket.material.material;
    } else {
      return "-";
    }
  };

  checkIfDev() && console.log("Socket.IO is Connected: " + isConnected);

  React.useEffect(() => {
    if (channelParamsId && !isLoadingChannelLovOpts) {
      setSelectedChannelOpt(
        channelLovOpts.find((item) => item.id === Number(channelParamsId))
      );
    }
  }, [channelParamsId, isLoadingChannelLovOpts]);
  React.useEffect(() => {
    if (warehouseParamsId && !isLoadingChannelWarehouseOpts) {
      setSelectedWarehouseOpt(
        channelWarehouseLovOpts.find(
          (item) => item.id === Number(warehouseParamsId)
        )
      );
    }
  }, [warehouseParamsId, isLoadingChannelWarehouseOpts]);

  const filterTrucksByStatus = (trucks?: AReservationWithWalkin[]) => {
    const nonDocking: AReservationWithWalkin[] = [];
    let docking: AReservationWithWalkin | undefined;

    if (trucks && trucks.length) {
      trucks.forEach((item) => {
        switch (item.statusDocking) {
          case "docking":
            docking = item;
            break;
          case "queue":
            nonDocking.push(item);
            break;
          default:
            break;
        }
      });
    }
    return {
      nonDocking,
      docking,
    };
  };

  // const filterTruckReservationNonDocking = (
  //   trucks: AReservationWithWalkin[] | undefined
  // ) => {
  //   if (!trucks) return [];
  //   let data = [...trucks];
  //   let filter = data.filter((item, index) => item.statusDocking !== "docking");
  //   return filter;
  // };

  // const truckFilteredbyDocking = (trucks: AReservationWithWalkin[]) => {
  //   if (trucks) {
  //     // if (!trucks) return;
  //     let allData: AReservationWithWalkin[] = [...trucks];
  //     let data = allData.find((item) => item.statusDocking === "docking");
  //     // console.log(data);
  //     return data;
  //   } else {
  //     return;
  //   }
  // };

  return (
    <StyledContainer>
      <StyledHeaderTitle variant="h3">
        Dock Management and Monitoring
      </StyledHeaderTitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 48,
        }}
      >
        <div style={{ display: "flex", flex: 0.5, gap: 12 }}>
          <Select
            id="channelOpts"
            options={channelLovOpts}
            value={selectedChannelOpt}
            isLoading={isLoadingChannelLovOpts}
            components={{
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={({ inputValue }) => {
              return `Channel ${inputValue} tidak ditemukan`;
            }}
            placeholder="Pilih Channel..."
            onChange={(value) => {
              // setSelectedChannelOpt(value);
              if (value) {
                searchParams.set("channelId", String(value?.value));
              } else {
                searchParams.delete("channelId");
              }
              searchParams.delete("warehouse");
              setSearchParams(searchParams);
            }}
            isClearable
            styles={customSelectStyle}
          />
          <Select
            id="warehouseOpts"
            placeholder={
              channelWarehouseLovOpts.length
                ? "Pilih Warehouse..."
                : "Tidak ada Warehouse"
            }
            options={channelWarehouseLovOpts}
            // isDisabled={!channelWarehouseLovOpts?.length}
            value={selectedWarehouseOpt}
            isLoading={isLoadingChannelWarehouseOpts}
            noOptionsMessage={({ inputValue }) => {
              return `Warehouse ${inputValue} tidak ditemukan`;
            }}
            onChange={(value) => {
              // setSelectedWarehouseOpt(value);
              if (value) {
                searchParams.set("warehouse", String(value?.value));
              } else {
                searchParams.delete("warehouse");
              }
              setSearchParams(searchParams);
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            styles={customSelectStyle}
          />
        </div>
        <TimeDisplayComponent style={{ flex: "0 0 20%" }} />
      </div>
      {!warehouseParamsId && (
        <p style={{ marginTop: 16 }}>Belum memilih gudang.</p>
      )}
      <StyledCardContainerScroller>
        {loading ? (
          <div>
            <CircularProgress
              size={24}
              color="primary"
              variant="indeterminate"
            />
          </div>
        ) : (
          truckDockReservationMemo?.map(({ truck, id, name }, index) => {
            const { docking: dockingTruck, nonDocking } =
              filterTrucksByStatus(truck);
            return (
              <div key={id}>
                {/* Truck is Docking */}
                <StyledCardContainerWarehouse
                  style={{
                    border: `1px solid ${COLORS.blue_border_1_warehouse}`,
                    background: COLORS.blue_1_warehouse,
                    minHeight: "fit-content",
                  }}
                >
                  <HeaderCardText>
                    <span style={{ width: "100%" }}>Dock {name}</span>
                  </HeaderCardText>
                  {!!dockingTruck && (
                    <StyledCard
                      colorVariant={
                        dockingTruck?.scheduleId ? COLORS.black_1 : COLORS.red_1
                      }
                      key={dockingTruck?.id}
                    >
                      <LicensePlateText>
                        {prettifyNopol(dockingTruck?.nopol ?? "-")}
                        {dockingTruck?.channel.channel === "FG" &&
                          ` • ${dockingTruck?.truck?.type ?? "-"}`}{" "}
                      </LicensePlateText>
                      {dockingTruck?.channel.channel === "FG" ? (
                        <CompanyTextWrapper>
                          <CompanyText>
                            {dockingTruck?.vendor?.name}
                          </CompanyText>
                          <NonCompanyText>
                            {dockingTruck?.channel.channel === "FG"
                              ? `${dockingTruck?.truck?.angkutan ?? "-"} • ${
                                  dockingTruck?.material?.material ?? "-"
                                }`
                              : ""}
                          </NonCompanyText>
                        </CompanyTextWrapper>
                      ) : (
                        <CompanyTextWrapper>
                          <CompanyText>
                            {dockingTruck?.vendor?.name}
                          </CompanyText>
                        </CompanyTextWrapper>
                      )}
                      <CompanyTextWrapper
                        style={{
                          borderRadius: 8,
                          margin: 16,
                          whiteSpace: "nowrap",
                          // fontSize: 16,
                        }}
                      >
                        <CompanyText
                          style={{ fontSize: 16, whiteSpace: "break-spaces" }}
                        >
                          {truck[0].startDockingTime &&
                            format(
                              new Date(truck[0].startDockingTime),
                              "dd-MMM-yyyy hh:mm:ss"
                            )}
                        </CompanyText>
                      </CompanyTextWrapper>
                    </StyledCard>
                  )}
                </StyledCardContainerWarehouse>
                {/* Truck is Not Docking */}
                <StyledCardContainerWarehouse style={{ marginTop: 16 }}>
                  <HeaderCardText>
                    <HeaderCardText>
                      <div style={{ width: "100%" }}>
                        Dock {name}
                        <span>[ {nonDocking.length} ]</span>
                      </div>
                    </HeaderCardText>
                  </HeaderCardText>
                  {nonDocking.length &&
                    nonDocking.map((reservationTruck, index) => (
                      <StyledCard
                        colorVariant={
                          reservationTruck.scheduleId
                            ? COLORS.black_1
                            : COLORS.red_1
                        }
                        key={reservationTruck.id}
                      >
                        <LicensePlateText>
                          {reservationTruck.nopol
                            ? prettifyNopol(reservationTruck.nopol)
                            : "-"}
                          {reservationTruck.channel.channel === "FG" &&
                            ` • ${getTruckTypeReservation(
                              reservationTruck
                            )}`}{" "}
                        </LicensePlateText>
                        {reservationTruck.channel.channel === "FG" ? (
                          <CompanyTextWrapper>
                            <CompanyText>
                              {reservationTruck.vendor?.name}
                            </CompanyText>
                            <NonCompanyText>
                              {reservationTruck.channel.channel === "FG"
                                ? `${getTruckAngkutanReservation(
                                    reservationTruck
                                  )} • ${getMaterialReservation(
                                    reservationTruck
                                  )}`
                                : ""}
                            </NonCompanyText>
                          </CompanyTextWrapper>
                        ) : (
                          <CompanyTextWrapper>
                            <CompanyText>
                              {reservationTruck.vendor?.name}
                            </CompanyText>
                          </CompanyTextWrapper>
                        )}
                        {/* <CompanyTextWrapper
                        style={{
                          borderRadius: 8,
                          margin: 16,
                          whiteSpace: "nowrap",
                          // fontSize: 16,
                        }}
                      >
                        <CompanyText style={{ fontSize: 16 }}>
                          {reservationTruck.startDockingTime &&
                            format(
                              new Date(truck[0].startDockingTime),
                              "dd-MMM-yyyy hh:mm:ss"
                            )}
                        </CompanyText>
                      </CompanyTextWrapper> */}
                      </StyledCard>
                    ))}
                </StyledCardContainerWarehouse>
              </div>
            );
          })
        )}
      </StyledCardContainerScroller>
    </StyledContainer>
  );
};

export default WarehouseDockMonitoringPage;
