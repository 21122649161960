export const COLORS = {
  white: "#FFFFFF",
  blue_1: "#2980B9",
  blue_2: "#3699FF",
  green: "#1ABC9C",
  green_1: "#16A085",
  green_2: "#27AE60",
  green_3: "#20C997",
  green_4: "#06C750",
  blue_1_warehouse: "#C3F1FF",
  blue_border_1_warehouse: "rgba(142, 187, 232, 1)",
  black_1: "#1C1C24",
  black_2: "#34495E",
  gray: "#EEF1F8",
  gray_1: "#D8E2EC",
  gray_2: "#EEF1F8",
  gray_3: "#C4CDE0",
  gray_4: "#687484",
  gray_5: "#A5AEC2",
  purple: "#9B59B6",

  light_gray_1: "#EEF1F8",

  brown: "#7A5547",

  red_1: "#FC5A5A",
  red_2: "#E74C3C",

  orange_1: "#E67E22",
  orange_2: "#E64D22",
} as const;
