import {
  add,
  differenceInSeconds,
  endOfDay,
  format,
  startOfDay,
} from "date-fns";
import { CSSProperties, FC, useEffect, useState } from "react";
import { StyledDateText } from "./style";

type Props = {
  style?: CSSProperties;
  refetch?: () => void;
};

const TimeDisplayComponent: FC<Props> = ({ style, refetch }) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const sec = differenceInSeconds(endOfDay(currentDate), currentDate);
    if (sec === 0 && refetch) {
      refetch();
    }
  }, [currentDate, refetch]);

  return (
    <StyledDateText title="current time" style={style}>
      <p>
        {format(currentDate, "dd-MMM-yyyy").toLocaleUpperCase()} •{" "}
        {format(currentDate, "HH:mm")}
      </p>
    </StyledDateText>
  );
};

export default TimeDisplayComponent;
