import { endOfDay, startOfDay } from "date-fns";

export function convertToEncodedURL(obj: { [key: string]: any }) {
  let formBody = [];
  for (const property in obj) {
    if (typeof obj[property] === "undefined") continue;
    const key = encodeURIComponent(property);
    const value = encodeURIComponent(obj[property]);
    formBody.push(`${key}=${value}`);
  }
  return formBody.join("&");
}

export const postSomethingToRN = (str?: string) => {
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(str);
};

type dateParam<D> = {
  startDate: D;
  endDate: D;
};

export const dateIsInRange = (
  date: Date | string,
  { startDate, endDate }: dateParam<string> | dateParam<Date>
): boolean => {
  let usedDate = new Date(date);
  let usedStart = startOfDay(new Date(startDate));
  let usedEnd = endOfDay(new Date(endDate));

  let dateIsBiggerThanStart = usedStart.getTime() <= usedDate.getTime();
  let dateIsSmallerThanEnd = usedEnd.getTime() >= usedDate.getTime();

  return dateIsBiggerThanStart && dateIsSmallerThanEnd;
};

/**
 *
 * @param str nopol
 * @returns nopol prettified
 * @example
 * ("AB123CDE")=>"AB 123 CDE"
 */
export const prettifyNopol = (str: string) => {
  let regex = new RegExp(/(\D+)(\d+)(\w+)/);
  const matches = str.match(regex);
  if (!matches) return str;
  // console.log(matches);
  const [_, ...rest] = matches;
  return rest.join(" ");
};

export const checkIfDev = () => {
  return process.env.NODE_ENV === "development";
};
