import React from "react";
import { StylesConfig } from "react-select";
import { COLORS } from "../assets/theme";

type Props = {};

const useCustomSelectStyle = () => {
  return React.useMemo<
    StylesConfig<
      {
        label: string;
        value: number;
      },
      false
    >
  >(
    () => ({
      container: (provided) => ({ ...provided, flex: 1 }),
      option: (provided, { isSelected }) => ({
        ...provided,
        color: COLORS.black_1,
        padding: 12,
        background: isSelected ? COLORS.gray_2 : "transparent",
        ":hover": {
          background: COLORS.gray_2,
        },
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: COLORS.black_1,
        };
      },
      control: (provided) => ({
        ...provided,
        padding: 4,
        background: COLORS.gray_2,
        boxShadow: "none",
        borderRadius: 8,
        border: `2px solid ${COLORS.gray_1}`,
        ":hover": {
          border: `2px solid ${COLORS.gray_1}`,
        },
        ":focus-visible": {
          border: `2px solid ${COLORS.gray_1}`,
        },
        ":focus-within": {
          border: `2px solid ${COLORS.gray_1}`,
        },
      }),
    }),
    []
  );
};

export default useCustomSelectStyle;
