import { Typography, TypographyProps } from "@material-ui/core";
import { useMemo } from "react";
import styled from "styled-components";
import { mq } from "../../assets/breakpoints";
import { COLORS } from "../../assets/theme";

export const StyledContainer = styled.div`
  min-height: 100vh;
  margin: 32px 16px;
`;

export const StyledCardWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  ${mq[2]} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  ${mq[3]} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  ${mq[4]} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
`;

export const StyledCardContainer = styled.div`
  border-radius: 16px;
  flex: 1;
  transition: all 0.3s;
  min-height: 100%;
  /* width: 100%; */
  background: ${COLORS.gray};
  padding: 16px;
`;
export const StyledCardContainerWarehouse = styled.div`
  border-radius: 16px;
  flex: 1;
  transition: all 0.3s;
  /* min-height: 100%; */
  /* width: 100%; */
  background: ${COLORS.gray};
  padding: 16px;
`;

export const StyledCardContainerScroller = styled.div`
  margin-top: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 19.1%;
  gap: 16px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
`;

export const HeaderCardText = styled.div`
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  padding: 16px 0;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  div:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 6px;
    /* margin-right: 4px; */
    span {
      font-size: 28px;
      word-wrap: break-word;
    }
    width: 100%;
    /* white-space: pre-wrap; */
  }
  div:last-child {
    /* margin-left: 4px; */
    transform: translateX(12px);
    ${mq[3]} {
      margin-right: 24px;
    }
    ${mq[6]} {
      margin-right: 56px;
    }
  }
  /* margin-bottom: 16px; */
`;
export const LicensePlateText = styled.h4`
  padding: 16px 16px 8px 16px;
  font-weight: 700;
  font-size: 24px;
  ${mq[3]} {
    font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  }
`;

export const CompanyTextWrapper = styled.div`
  font-weight: 600;
  color: ${COLORS.white};
  padding: 12px 16px;
  border-radius: 0px 0px 12px 12px;
`;
export const CompanyTextWrapperWarehouse = styled.div`
  font-weight: 600;
  color: ${COLORS.white};
  padding: 12px 16px;
  /* border-radius: 0px 0px 12px 12px; */
`;

export const CompanyText = styled.span`
  font-size: 22px;
  ${mq[3]} {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
  }
  width: 100%;
`;

export const NonCompanyText = styled.span`
  font-size: 22px;
  ${mq[3]} {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  }
  width: 100%;
`;

export const StyledCard = styled.div<{ colorVariant: string }>`
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 4px 12px 0px #6874844a;
  background: ${COLORS.white};
  margin-bottom: 24px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
  color: ${(props) =>
    props.colorVariant ? props.colorVariant : COLORS.black_1};
  ${CompanyTextWrapper} {
    background: ${(props) =>
      props.colorVariant ? props.colorVariant : COLORS.black_1};
  }
`;

export const PassedDateIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  background-color: ${COLORS.red_2};
  top: 8px;
  right: 8px;
`;

export const StyledHeaderContainer = styled.div`
  margin-top: 48px;
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  ${mq[2]} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    flex-direction: row;
  }
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledDateText = styled.div`
  background: ${COLORS.gray_2};
  height: 100%;
  font-size: 20px;
  ${mq[3]} {
    font-size: calc(8px + 6 * ((100vw - 320px) / 680));
  }
  display: flex;
  flex: 1;
  color: ${COLORS.black_1};
  border-radius: 8px;
  border: 2px solid ${COLORS.gray_1};
  align-items: center;
  & > p {
    font-weight: 600;
    text-align: center;
    flex: 1;
    padding: 0.65rem;
    margin: 0;
  }
`;

export const StyledHeaderTitle = styled((props: TypographyProps) => (
  <Typography {...props} />
))`
  font-weight: 700 !important;
  text-align: center;
`;
