import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import { BackendDataShape } from "../../types";
import {
  AReservation,
  AWEReservationParams,
  AWIReservation,
  ReservationRequestObj,
} from "../../types/reservation";
import { BASIC_URL } from "../basicurl";

export const guestReservationstoreAPI = createApi({
  reducerPath: "guestReservationstoreAPI",
  tagTypes: [
    "GuestReservationList",
    "GuestReservation",
    "GuestWalkinReservation",
    "GuestWalkinReservationList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}`,
  }),
  endpoints: (builder) => {
    return {
      getGuestReservation: builder.query<AReservation[], ReservationRequestObj>(
        {
          query: (obj) => {
            let params = "";
            if (obj) {
              params = convertToEncodedURL(obj);
              params = params.length ? "?" + params : "";
            }
            return {
              url: `/reservation/find/noauth` + params,
              method: "GET",
            };
          },
          providesTags: () => ["GuestReservationList"],
          transformResponse: (res: BackendDataShape<AReservation[]>) => {
            return res.data;
          },
        }
      ),
      getGuestWalkinReservation: builder.query<
        AWIReservation[],
        AWEReservationParams
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: `/reservationwalkin/find/noauth` + params,
            method: "GET",
          };
        },
        providesTags: () => ["GuestReservationList"],
        transformResponse: (res: BackendDataShape<AWIReservation[]>) => {
          return res.data;
        },
      }),
      getGuestReservationById: builder.query<
        AReservation[],
        Pick<ReservationRequestObj, "qrid">
      >({
        query: ({ qrid }) => {
          return {
            url: `/reservation/find/qr/${qrid}`,
            method: "GET",
          };
        },
        providesTags: () => ["GuestReservation"],
        transformResponse: (res: BackendDataShape<AReservation[]>) => {
          return res.data;
        },
      }),
      getGuestWalkinReservationById: builder.query<
        AWIReservation[],
        Pick<AWEReservationParams, "qrid">
      >({
        query: ({ qrid }) => {
          return {
            url: `/reservationwalkin/find/qr/${qrid}`,
            method: "GET",
          };
        },
        providesTags: () => ["GuestWalkinReservation"],
        transformResponse: (res: BackendDataShape<AWIReservation[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetGuestReservationQuery,
  useGetGuestWalkinReservationQuery,
  useGetGuestReservationByIdQuery,
  useGetGuestWalkinReservationByIdQuery,
  util: { resetApiState: resetGuestReservationState },
} = guestReservationstoreAPI;
